import Vue from 'vue'
import VueRouter from 'vue-router'
import { isMobile } from '../utils/index'

// import PcIndex from '../views/Pc/index.vue'
// import MobileIndex from '../views/Mobile/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'pc',
    component: () =>
      import(/* webpackChunkName: "pc" */ '../views/Pc/index.vue')
  },
  {
    // path: '/mobile',
    // name: 'about',
    // // route level code-splitting
    // // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ MobileIndex)
    path: '/mobile', // 根路由
    name: 'mobile',
    component: () =>
      import(/* webpackChunkName: "mobile" */ '../views/Mobile/index.vue'),
    children: [
      {
        name: 'mobile.index',
        path: 'index', // 首页
        component: () =>
          import(
            /* webpackChunkName: "index" */ '../views/Mobile/index.vue'
          )
      }
    ]
  },
  {
    path: '*',
    redirect: '/index'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(isMobile())
  console.log(to)
  if (isMobile() && to.name && to.name.indexOf('mobile') < 0) {
    console.log('手机端')
    window.location.href = '/mobile/index/' + to.fullPath.replace(to.path, '')
  } else if (!isMobile() && to.name && to.name.indexOf('mobile') >= 0) {
    console.log('pc端')
    window.location.href = '/index/' + to.fullPath.replace(to.path, '')
  } else {
    next()
  }
})

export default router
