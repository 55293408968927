import {init as langInit, get_language_set} from '@/utils/language'

var chooce_language = null;
export function isMobile () {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}

export function isAndroid () {
  let u = navigator.userAgent
  // console.log(u)
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
  return isAndroid
}

export function isNull(data) {
  return data == null || data == undefined;
}

export function setBrowserLanguage(language){
  chooce_language = language;
}

export function getBrowserLanguage() {
  return chooce_language == null ? (navigator.language || navigator.browserLanguage).toLowerCase() : chooce_language;
}

export function init() {
  return langInit();
}

export function lang(key) {
  var language = getBrowserLanguage();
  var language_set = get_language_set();
  if (!language_set.hasOwnProperty(language)){
    language = 'en-us';
  }
  return language_set[language].hasOwnProperty(key) ? language_set[language][key] : '';
}
