import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta';

import '@assets/style/reset.less'

import 'amfe-flexible/index.js'
Vue.config.productionTip = false

Vue.use(Meta);

new Vue({
  router,
  store,
  data: {
    title: '光速虚拟机官网',
    keywords: '虚拟手机，微信多开，微信分身，手游挂机，ROOT',
    description: '光速虚拟机，安全免费的虚拟手机，手机上的虚拟机，全球领先的引擎（基于安卓7.1），完美极致的性能，安卓用户的最爱，装机必备APP',
    og_locale: 'zh_CN',
    og_type: 'website',
    og_title: '光速虚拟机官网',
    og_description: '光速虚拟机，安全免费的虚拟手机，手机上的虚拟机，全球领先的引擎（基于安卓7.1），完美极致的性能，安卓用户的最爱，装机必备APP',
    og_url: 'https://gsxnj.cn',
    og_site_name: '光速虚拟机',
    og_image: 'https://static.gsxnj.cn/images/img/index/img_1.61fcfcb8.png',
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "keywords",
          content: this.keywords
        },
        {
          name: "description",
          content: this.description
        },
        {
          property: "og:locale",
          content: this.og_locale
        },
        {
          property: "og:type",
          content: this.og_type
        },
        {
          property: "og:title",
          content: this.og_title
        },
        {
          property: "og:description",
          content: this.og_description
        },
        {
          property: "og:url",
          content: this.og_url
        },
        {
          property: "og:site_name",
          content: this.og_site_name
        },
        {
          property: "og:image",
          content: this.og_image
        },
        {
          itemprop: "name",
          content: this.title
        },
        {
          itemprop: "image",
          content: this.og_image
        }
      ]
    }
  },
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
