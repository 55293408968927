import axios from 'axios'
import { Toast } from 'vant'

export function request(url, method, headers, data){
    return axios({
        method: method,
        url: url,
        headers: headers,
        data: data,
        responseType: 'json',
      }).then((res) => {
        if (res.status !== 200) {
          Toast({
            position: 'bottom',
            message: '网络繁忙',
          })
          throw new Error(`${requestSource} network status ${res.status}`)
        } else {
          return res.data
        }
      })
}