import {request} from '@/utils/http'
var language_set = {};
export async function init(){
    return request('https://www.gsxnj.cn/get_language_set.php', 'get', null, null)
    .then((res) => {
        language_set = res;
    });
}
export function get_language_set(){
    return language_set;
}